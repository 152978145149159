<template>
  <div>
    <!-- Main program card -->
    <v-card elevation="0" outlined v-if="program">
      <v-card-title class="d-flex align-center">
        <v-row class="align-center justify-center">
          <v-col cols="9" class="align-self-center">
            <span class="mr-2 mt-n1 pa-1"
            >

              {{ program.name }}

              <v-chip small label dark color="#7e7e7e" class="mr-2">
                {{ program.id }}
              </v-chip>
              <v-chip small v-if="program.is_active" dark color="#1ac8aa">{{
                  $t("view.companies.active_program")
                }}
              </v-chip>
            </span>

            <v-card-subtitle class="mr-2 mt-n1 pa-2" color="cfcfcf">
              <i>{{ program.start_date }} - {{ program.end_date }}</i>
            </v-card-subtitle>
          </v-col>
          <v-col
            cols="3"
            class="d-flex justify-end flex-column align-end align-self-start"
          >
            <span class="d-flex justify-end align-end align-center">
              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="startNewCampaign()" class="ma-2"
                  >mdi-plus</v-icon
                  >
                </template>
                <span>{{ $t("view.companies.new_campaign") }}</span>
              </v-tooltip>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="edit()" class="ma-2"
                  >mdi-pencil-box-outline</v-icon
                  >
                </template>
                <span>{{ $t("view.companies.edit_a_program") }}</span>
              </v-tooltip>

              <v-tooltip slot="append" bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="suppress()" class="ma-2"
                  >mdi-delete</v-icon
                  >
                </template>
                <span>{{ $t("view.companies.delete_a_program") }}</span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <div class="box-a">
          <oppens-campaign
            v-for="campaign in program.campaigns"
            :key="campaign.id"
            :campaign="campaign"
            :selectedSimulation="localSelectedSimulation"
            v-on:save="saveCampaign"
            v-on:suppress="suppressCampaign"
            v-on:generateReport="localGenerateCampaignReport"
            v-on:selection="setLanguage"
            v-on:selectionTypeOfReport="setTypeOfReport"
            v-on:refresh="refresh"
            class="my-custom-margin oppens-campaign mb-8"
            @handleRefreshProgramSinceCampaign="handleRefreshProgram"
          >
          </oppens-campaign>
        </div>
      </v-card-text>
    </v-card>

    <!-- new program dialog -->
    <v-dialog
      v-model="dialogProgram"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex, backgroundColor: options.color }"
      class="dialog"
    >
      <v-card outlined color="#fafafa">
        <v-card-title class="d-flex"> {{ title }}</v-card-title>
        <oppens-program-edition
          v-if="reveal"
          :program="program"
          v-on:save="save"
          v-on:close="close"
          class="container"
        >
        </oppens-program-edition>
      </v-card>
    </v-dialog>

    <!-- new campaign dialog -->
    <v-dialog
      v-model="dialogCampaign"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex, backgroundColor: options.color }"
      class="dialog"
    >
      <v-card outlined color="#fafafa" v-if="newCampaign">
        <v-card-title class="d-flex"> {{ title }}</v-card-title>
        <oppens-campaign-edition
          :campaign="newCampaign"
          v-on:save="saveNewCampaign"
          v-on:close="cancelCampaignCreation"
          class="container"
        >
        </oppens-campaign-edition>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import campaignsMixin from "@/mixins/campaigns.mixin"
import moment from "moment"
import { showSnackbar } from "@/services/GlobalActions"
import { i18n } from "@/plugins/i18n"

export default {
  name: "oppens-program",
  mixins: [campaignsMixin],
  props: {
    program: {
      default: null,
    },
    companyUuid: {
      default: null,
    },
    selectedSimulation: {
      default: null,
    },
  },
  data: () => ({
    options: {
      width: 600,
      zIndex: 200,
    },
    title: "",
    dialogProgram: false,
    dialogCampaign: false,
    language: "fr",
    reveal: false,
    newCampaign: null,
    simulationIsTest: null,
    localSelectedSimulation: null,
  }),

  mounted() {
    this.localSelectedSimulation = this.selectedSimulation
  },

  created() {
    this.$parent.$on("update:selectedSimulation", this.updateSelectedSimulation)
  },

  methods: {
    updateSelectedSimulation(newVal) {
      this.localSelectedSimulation = newVal
    },
    closeDialogs() {
      this.dialogProgram = false
      this.dialogCampaign = false
    },
    refresh() {
      this.$emit("refresh")
      this.newCampaign = null
    },
    startNewCampaign() {
      this.title = i18n.t("view.companies.new_campaign")
      this.dialogCampaign = true
      this.newCampaign = {
        name:
          i18n.t("view.companies.campaign") +
          " " +
          moment().format("YYYYMM") +
          "-x",
        description: "",
        program_id: this.program.id,
      }
    },

    cancelCampaignCreation() {
      this.newCampaign = null
      this.title = ""
      this.closeDialogs()
    },
    edit() {
      this.title = i18n.t("view.companies.edit_a_program")
      this.dialogProgram = true
      this.reveal = true
    },
    async saveNewCampaign(data) {
      await this.saveCampaign(data) // call mixin method
      this.reveal = false
      this.closeDialogs()
    },
    save(data) {
      this.$emit("save", data)
      this.reveal = false
      this.closeDialogs()
    },
    suppress() {
      this.$emit("suppress", this.program.id)
      this.reveal = false
    },
    close() {
      this.title = ""
      this.reveal = false
      this.closeDialogs()
    },
    setLanguage(selectedLanguage) {
      this.language = selectedLanguage
    },
    setTypeOfReport(selectedTypeOfReport) {
      this.typeOfReport = selectedTypeOfReport
    },
    async localGenerateCampaignReport(campaignId) {
      const response = await this.generateCampaignReport(
        campaignId,
        this.language,
        this.typeOfReport,
      )

      if (
        response === i18n.t("Campaign without service, no report generated")
      ) {
        showSnackbar(response,"#FF5252")
      } else if (typeof response === "undefined") {
        showSnackbar(
          i18n.t("An error has occurred in the generation of the report"),"#FF5252"
        )
      } else {
        showSnackbar(i18n.t("The report has been generated"),"#1AC8AA")
      }
    },

    handleRefreshProgram(data) {
      this.$emit("handleRefreshProgram", data)
    },
  },
}
</script>
<style scoped lang="scss">
.box-a {
  display: inline-block;
  width: 100%;
  padding: 10px;
}

.container {
  border: 0.1px solid #e0e0e0;
  border-radius: 4px;
  padding: 8px;
}

.my-custom-margin {
  margin-top: -30px;
}
</style>
