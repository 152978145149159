import LearningLogic from "@/logics/LearningLogic"
import { showSnackbar } from "@/services/GlobalActions"
import utilsMixin from "@/mixins/utils.mixin"
import { i18n } from "@/plugins/i18n"

export default {
  mixins: [utilsMixin],
  data: () => ({
    allowedTypes: ["slides", "assets", "answers", "courses", "hat-courses"],
    dataList: null,
    type: null,
  }),

  methods: {
    typeIsAllowed(type) {
      if (!this.allowedTypes.includes(type)) {
        showSnackbar("Type not allowed " + type, "#FF5252")
        return false
      }
      return true
    },

    getOppensLearningResources(type) {
      if (!this.typeIsAllowed(type)) return

      return this._withLoading(async () => {
        this.dataList = null
        return LearningLogic.getOppensLearningResources(type)
      })
    },

    deleteOppensLearningResource(type, data) {
      return this._withLoading(async () => {
        await LearningLogic.deleteOppensLearningResources(type, data)
        this.loading = false
        this.refresh()
      })
    },

    saveOppensLearningResource(type, data) {
      if (!this.typeIsAllowed(type)) return

      return this._withLoading(async () => {
        const result = await LearningLogic.pushResource(
          type,
          data
        )
        this.loading = false
        this.refresh()
        return result
      })
    },

    async confirmDelete(data) {
      if (
        await this.$refs.confirm.open(
          i18n.t("view.companies.confirm_delete"),
          i18n.t("view.companies.confirm_delete_resource_message"),
          i18n.t("Cancel")
        )
      ) {
        await this.deleteOppensLearningResource(this.type, data)
      }
    },

    buildNewDataObject(type) {
      if (!this.typeIsAllowed(type)) return

      const templates = {
        slides: {
          ismultivalue: false,
          title: "",
          lang: "fr",
          description: "",
          feedback: "",
          feedback_long: "",
          illustration: null,
          html_illustration: null,
          answers: [],
        },
        answers: {
          description: "",
          score: 0,
          lang: "fr",
        },
        courses: {
          title: "",
          description: "",
          lang: "fr",
          hat_course_id: null,
          slides: [],
        },
        "hat-courses": {
          title: "",
          description: "",
          image: null,
          duration: 7,
        },
        assets: {},
      }

      return templates[type] || null
    },

    saveCourse(courseId) {
      return this._withLoading(() => LearningLogic.duplicateCourse(courseId))
    },
  },
}
