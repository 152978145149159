<template>
  <v-container fluid style="width: 100%; height: 100%">
    <div>
      <v-row>
        <v-col>
          <div>
            <v-row>
              <v-col class="ml-3 mr-3 mt-3">
                <v-file-input
                  chips
                  counter
                  multiple
                  show-size
                  truncate-length="50"
                  :label="$t('users.files')"
                  v-model="files"
                ></v-file-input>
                <v-btn color="green" dark @click="submit()" class="mt-2 text-none rounded-lg"
                  >{{ $t("users.upload") }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <FilesComponent ref="fileList" :company="company" :company-uuid="companyUuid" />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>

<script>
import FileLogic from "../../logics/FileLogic"
import FilesComponent from "./ViewFiles.vue"
import CompanyLogic from "@/logics/CompanyLogic"
import { showSnackbar, unAuthorized } from "@/services/GlobalActions"
import { i18n } from "@/plugins/i18n"

export default {
  components: {
    FilesComponent,
  },

  props: {
    company: null,
  },

  data: () => ({
    files: null,
    snackbar: false,
    localCompany: null,
  }),

  created() {
    if (this.$route.params.companyUuid) {
      this.uuid = this.$route.params.companyUuid

      CompanyLogic.getOne(this.uuid)
        .then((data) => {
          this.localCompany = data
        })
        .catch((error) => {
          console.error("error: ", error)
        })
    }
  },

  computed: {
    companyUuid() {
      return this.$route.params.companyUuid
    },
  },

  methods: {
    submit() {
      if (!this.files || this.files.length === 0) {
        showSnackbar(i18n.t("files.youMustProvideFile"), "#FF5252")
        return
      }

      const files = []
      for (const file of this.files) {
        files.push(file)
      }

      const companyUuid = this.company ? this.localCompany.uuid : this.companyUuid

      FileLogic.uploadCompanyFiles(companyUuid, files).then((res) => {
        this.files = null
        this.$refs.fileList.getCompanyFiles()
      })
    },
  },
}
</script>
