<script>
import { i18n } from "@/plugins/i18n"
import { debounce } from "lodash"
import moment from "moment"
moment.locale("fr")
import AuthStore from "@/stores/AuthStore"

export default {
  props: {
    value: Object,   // Simulation object
    campaigns: Array,
    employeeGroups: Array,
    exercises: Array,
    isSystemAdmin: Boolean,
    exerciseTags: Object,
  },
  data() {
    return {
      localSimulation: null,
      selectedEmployeeGroupIds: [],
      showModalExercice: false,  // Control the visibility of the exercise selection modal
      selectedExercise: null,
      selectedExerciseTitle: null,
      todayString: new Date().toISOString().substr(0, 10),  // Today's date
      requiredRule: v => !!v || "Field is required",  // Required field rule
      search: "",  // Search string for exercises
      selectedLanguage: "",
      langArray: [
        { code: "", label: i18n.t("view.learning_phishing.all_languages") },
        { code: "de", label: "Deutsch" },
        { code: "nl", label: "Dutch" },
        { code: "en", label: "English" },
        { code: "es", label: "Español" },
        { code: "fr", label: "Français" },
        { code: "it", label: "Italiano" },
        { code: "pl", label: "Polska" },
        { code: "pt", label: "Português" },
        { code: "ro", label: "Română" },
        { code: "sv", label: "Svenska" },
        { code: "tr", label: "Türkçe" },
      ],
      timezoneOptions: [
        { label: "Europe/Paris", value: "Europe/Paris" },
        { label: "Europe/London", value: "Europe/London" },
        { label: "America/New_York", value: "America/New_York" },
        { label: "America/Los_Angeles", value: "America/Los_Angeles" },
        { label: "Asia/Tokyo", value: "Asia/Tokyo" },
        { label: "Asia/Shanghai", value: "Asia/Shanghai" },
        { label: "Australia/Sydney", value: "Australia/Sydney" },
        { label: "Australia/Perth", value: "Australia/Perth" },
      ],
      selectedTimezone: null,
      isLoadingExercises: true,
    }
  },
  watch: {
    exercises:{
      immediate: true,
      handler(newValue) {
        if (newValue) {

          if (!newValue) return;

          const exercises = newValue;

          console.log("exercises", exercises)

          if(exercises?.length > 0){
            console.log("no longer loading exercises")
            this.isLoadingExercises = false
          }

          if(
            exercises?.length > 0 &&
            this.localSimulation?.phishing_exercise_id ){
            this.selectedExercise =
              exercises.find((e) => e.id === this.localSimulation.phishing_exercise_id) || {}
            this.selectedExerciseTitle = this.selectedExercise.title
          }
        }
      },
    },
    value: {
      immediate: true,
      handler(newValue) {

        if (!newValue) return;


        // Find the timezone object in the options list based on the stored value
        const selected = this.timezoneOptions.find(
          (tz) => tz.value === newValue.timezone
        );

        const myTimezone = AuthStore.getMyTimezone() ?? "Europe/Paris";
        this.selectedTimezone = selected || { label: myTimezone, value: myTimezone };

        // Initialize `selectedEmployeeGroupIds` only if `employee_group_ids` has changed
        const newGroupIds = newValue.employee_group_ids || [];
        if (JSON.stringify(this.selectedEmployeeGroupIds) !== JSON.stringify(newGroupIds)) {
          this.selectedEmployeeGroupIds = newGroupIds;
        }

        // init selected exercise
        if(newValue.phishing_exercise_id && this.exercises?.length > 0){

          this.selectedExercise =
            this.exercises.find((e) => e.id === newValue.phishing_exercise_id) || {}
          this.selectedExerciseTitle = this.selectedExercise.title
        }

        // Deep copy the simulation to avoid mutations
        this.localSimulation = JSON.parse(JSON.stringify(newValue))
      },
    },
    // Only watch specific fields, not the entire object
    "localSimulation.name": function(newName) {
      this.checkForChanges()
    },
    "localSimulation.campaign_id": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.is_test": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.launch_date": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.send_by_date": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.launch_time": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.send_by_time": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.phishing_exercise_id": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.has_automatic_launch": function(newCampaignId) {
      this.checkForChanges()
    },
    "localSimulation.has_automatic_stop": function(newCampaignId) {
      this.checkForChanges()
    },
    selectedEmployeeGroupIds: function(newEmployeeGroups) {
      this.checkForChanges()
    },
    selectedTimezone(newTimezone) {
      this.localSimulation.timezone = newTimezone;
      this.checkForChanges(); // Ensure changes are detected
    },
  },
  methods: {
    async updateLaunchDate(data) {
      this.localSimulation.launch_date = data
    },
    updateSendByDate(date) {
      this.localSimulation.send_by_date = date
    },
    selectExercise(exercise) {
      this.localSimulation.phishing_exercise_id = exercise.id
      this.selectedExercise = exercise
      this.selectedExerciseTitle = exercise.title
      this.showModalExercice = false
    },
    getStatus(status) {
      const statusMap = {
        up: { icon: "mdi-check-circle", color: "green" },
        down: {
          icon: "mdi-close-circle",
          color: "red",
        },
        unknown: { icon: "mdi-alert-circle-outline", color: "grey darken-3" },
      }

      return statusMap[status] || statusMap.unknown
    },
    // Tooltip content for server status
    getStatusTooltip(lastCheck) {
      return `${this.$t("view.learning_phishing.last_checked")}: ${lastCheck}`
    },
    // Function to compare specific fields and detect changes
    hasSimulationChanged() {
      const original = this.value
      const current = this.localSimulation

      // sometimes the selectedTimezone is not set
      if(this.selectedTimezone && original.timezone !== this.selectedTimezone?.value){
        return true
      }

      if (
        original.name !== current.name ||
        JSON.stringify(original.employee_group_ids) !== JSON.stringify(this.selectedEmployeeGroupIds) ||
        original.campaign_id !== current.campaign_id ||
        original.is_test !== current.is_test ||
        original.launch_date !== current.launch_date ||
        original.send_by_date !== current.send_by_date ||
        original.launch_time !== current.launch_time ||
        original.send_by_time !== current.send_by_time ||
        original.phishing_exercise_id !== current.phishing_exercise_id ||
        original.has_automatic_launch !== current.has_automatic_launch ||
        original.has_automatic_stop !== current.has_automatic_stop
      ) {
        return true
      }
      return false

    },
    // Debounced check to emit changes
    checkForChanges: debounce(function() {
      if (this.hasSimulationChanged()) {
        const updatedSimulation = {
          ...this.localSimulation,
          employee_group_ids: this.selectedEmployeeGroupIds,
          timezone: this.selectedTimezone?.value ?? this.localSimulation.timezone,
        }
        // Emit only if changes have occurred
        this.$emit("input", updatedSimulation)
      }
    }, 300),  // 300ms debounce to avoid too frequent emissions
  },
  computed: {
    loaded() {
      if (this.localSimulation && this.campaigns && this.employeeGroups /*&& this.exercises*/) {
        return true
      }
      return false
    },
    noExercisesFound() {
      return this.filteredExercises.length === 0
    },
    filteredExercises() {
      if(!this.exercises) return []
      return this.exercises
        .filter((exercise) => {
          const matchesSearch =
            exercise.title.toLowerCase().includes(this.search.toLowerCase()) ||
            (exercise.theme &&
              exercise.theme.toLowerCase().includes(this.search.toLowerCase()))

          const matchesLanguage =
            this.selectedLanguage === ""
              ? true
              : exercise.lang === this.selectedLanguage

          return matchesSearch && matchesLanguage
        })
        .sort((a, b) => a.title.localeCompare(b.title))
    },


    availableLangArray() {
      const exercises = this.exercises || [];
      const availableLangs = exercises.reduce((acc, exercise) => {
        if (!acc.includes(exercise.lang)) {
          acc.push(exercise.lang)
        }
        return acc
      }, [])

      return this.langArray.filter(
        (lang) => lang.code === "" || availableLangs.includes(lang.code),
      )
    },
  },
}
</script>


<template>
  <v-card elevation="0" outlined color="#fafafa" v-if="loaded">
    <div  class="main-container">
      <v-card-text>
        <!-- Simulation Name, Campaign, Employee Group -->
        <v-row>
          <v-col cols="4">
            <v-text-field
              :label="$t('view.companies.simulation_name')"
              v-model="localSimulation.name"
              class="required"
              :rules="[requiredRule]"
            />
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="localSimulation.campaign_id"
              :items="campaigns"
              item-text="name"
              item-value="id"
              :label="$t('view.companies.select_campaign')"
              class="required"
              :disabled="localSimulation.is_active"
              :rules="[requiredRule]"
            />
          </v-col>

          <v-col cols="4">
            <v-select
              v-model="selectedEmployeeGroupIds"
              :items="employeeGroups"
              :disabled="localSimulation.is_active || localSimulation.is_stopped"
              multiple
              item-text="name"
              item-value="id"
              :label="$t('view.companies.select_employee_group')"
              class="required"
              :rules="[requiredRule]"
            />
          </v-col>
        </v-row>

        <!-- Modal for Selecting Exercise -->
        <v-row>
          <v-col cols="4">
            <v-text-field
              :disabled="localSimulation.is_active"
              v-model="selectedExerciseTitle"
              filled
              :label="$t('view.companies.select_exercise')"
              readonly
              @click="showModalExercice = true"
            >
              <template #append>
                <v-progress-circular
                  v-if="isLoadingExercises"
                  indeterminate
                  size="20"
                  class="mr-2"
                />
                <v-icon @click="showModalExercice = true">mdi-menu-down</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <!-- Test Type -->
          <v-col cols="4" v-if="isSystemAdmin">
            <v-select
              v-model="localSimulation.is_test"
              :items="[
                  { label: 'Normal', value: false },
                  { label: 'Test', value: true }
                ]"
              item-text="label"
              item-value="value"
              filled
              :label="$t('view.companies.simulation_type')"
              class="required"
              :disabled="localSimulation.is_active"
            >
              <template #selection="{ item }">
                {{ item.label }}
              </template>
              <template #item="{ item }">
                {{ item.label }}
              </template>
            </v-select>
          </v-col>

          <!-- Timezone -->
          <v-col cols="4">
            <v-combobox
              v-model="selectedTimezone"
              :items="timezoneOptions"
              item-text="label"
              item-value="value"
              :label="$t('view.companies.select_timezone')"
              :disabled="localSimulation.is_active"
              clearable
            />
          </v-col>

          <!-- Tags Indicator Label and Icon -->
          <v-col cols="4" v-if="exerciseTags" class="mb-2">
            <v-chip color="#8000ff" outlined>
              <v-icon left color="#8000ff">mdi-tag</v-icon>
              {{ $t('view.companies.exercise_with_tags') }}
            </v-chip>
          </v-col>
        </v-row>

        <!-- Date Pickers -->
        <v-row>
          <v-col cols="6">
            <op-datepicker
              ref="datepicker"
              :label="$t('Start date of shipments')"
              :date="localSimulation.launch_date"
              :minDate="todayString"
              v-on:update="updateLaunchDate"
              class="required"
              :simulationIsActive="localSimulation.is_active"
            />
          </v-col>

          <v-col cols="6">
            <op-datepicker
              :label="$t('End date of shipments')"
              :date="localSimulation.send_by_date"
              v-on:update="updateSendByDate"
              min="localSimulation.launch_date"
              :simulationIsActive="localSimulation.is_active"
              :minDate="todayString"
            />
          </v-col>
        </v-row>

        <!-- Time Pickers -->
        <v-row>
          <v-col cols="6">
            <v-time-picker
              color="green lighten-1"
              format="24hr"
              v-model="localSimulation.launch_time"
              :readonly="localSimulation.is_active"
            />
          </v-col>
          <v-col cols="6">
            <v-time-picker
              v-model="localSimulation.send_by_time"
              format="24hr"
              :readonly="localSimulation.is_active"
            />
          </v-col>
        </v-row>


        <!-- Dialog for Selecting Exercise -->
        <v-dialog v-model="showModalExercice" max-width="80%">
          <v-card>
            <v-card-title>
              <div style="width: 100%; display: inline-block">
                {{ $t("view.companies.select_exercise") }}
              </div>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('view.companies.search')"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :items="availableLangArray"
                    v-model="selectedLanguage"
                    item-text="label"
                    item-value="code"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-container>
                <div v-if="noExercisesFound" class="text-center my-4">
                  {{ $t("view.companies.no_exercises_found") }}
                </div>
                <div v-else class="d-flex flex-wrap justify-space-around">
                  <v-card
                    v-for="exercise in filteredExercises"
                    :key="exercise.id"
                    class="exercise-card mb-4"
                    @click="selectExercise(exercise)"
                    width="300"
                  >
                    <v-img
                      :src="exercise.illustration_url || require('@/assets/exercice-image.png')"
                      aspect-ratio="1"
                      height="150"
                    />
                    <div class="title-card">{{ exercise.title }}</div>
                    <v-divider class="mx-4" />
                    <div class="subtitle">{{ $t("Theme") }}: {{ exercise.theme }}</div>
                    <div class="subtitle">{{ $t("Difficulty") }} : {{ exercise.difficulty || "-" }}</div>
                    <div class="subtitle">{{ $t("Difficulty") }} : {{ exercise.difficulty || "-" }}</div>
                    <!-- Server Status -->
                    <div>
                    <span class="subtitle">
                      {{ $t("view.learning_phishing.server_status") }}:
                    </span>
                      <v-icon
                        :color="getStatus(exercise.phishing_server.monitoring?.status).color"
                        class="tooltip"
                        :title="getStatusTooltip(exercise.phishing_server.monitoring?.last_check)"
                      >
                        {{ getStatus(exercise.phishing_server.monitoring?.status).icon }}
                      </v-icon>
                    </div>
                  </v-card>
                </div>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn @click="showModalExercice = false" text>{{ $t("buttons.close") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


        <!-- Automation Section -->
        <div class="mt-6 text-h6">
          <v-icon class="mr-2">mdi-settings</v-icon>
          {{ $t("view.companies.automation") }}
        </div>
        <v-divider class="my-2"></v-divider>

        <v-row>
          <v-col class="my-0 py-0" cols="6">
            <v-switch
              v-model="localSimulation.has_automatic_launch"
              :disabled="localSimulation.is_active"
              :label="$t('Automatic start-up')"
              color="green"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="my-0 py-0" cols="6">
            <v-switch
              v-model="localSimulation.has_automatic_stop"
              :label="$t('view.companies.simulation_automatic_stop')"
              color="green"
            ></v-switch>
          </v-col>
        </v-row>

      </v-card-text>

    </div>
  </v-card>
</template>

<style scoped>
.main-container {
  min-height: 85vh;
}

.exercise-card {
  cursor: pointer;
  min-width: 220px;
  max-width: 19%;
}

.title-card {
  font-weight: bold;
  margin: 10px 0 10px 5px;
}
</style>
