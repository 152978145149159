<template>
  <div v-if="localData">
    <op-modale
      :display="show"
      :data="html"
      v-on:closed="closedPreview"
    ></op-modale>
    <v-card elevation="0" outlined color="#fafafa" class="ma-1">
      <v-card-text>
        <v-text-field
          :label="$t('view.learning_phishing.template_name')"
          v-model="localData.name"
          class="required"
        >
        </v-text-field>

        <v-text-field
          :label="$t('view.learning_phishing.template_subject')"
          v-model="localData.subject"
          class="required"
        >
        </v-text-field>

        <v-textarea
          :label="$t('view.learning_phishing.email_text')"
          v-model="localData.text"
        >
        </v-textarea>
        <!-- Tag syntax help section -->
        <TagHelpCard/>


        <v-textarea
          :label="$t('view.learning_phishing.email_html')"
          v-model="localData.html"
          class="required"
        >
        </v-textarea>

        <v-select
          :label="$t('view.learning_phishing.select_attachments')"
          v-model="localData.attachments"
          :items="assetList"
          item-text="name"
          item-value="name"
          multiple
          chips
          small-chips
        ></v-select>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="2" @click="submit()" dark color="green" class="text-none rounded-lg"
          >{{ $t("buttons.save") }}
        </v-btn>
        <v-btn elevation="2" @click="close()" class="text-none rounded-lg">{{
          $t("buttons.close")
        }}</v-btn>
        <v-btn elevation="2" @click="openPreview()" class="text-none rounded-lg">{{
          $t("buttons.preview")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import phishingMixin from "@/mixins/phishing.mixin.js"
import { showSnackbar } from "@/services/GlobalActions"

export default {
  mixins: [phishingMixin],
  name: "phishing-email-template-edition",
  props: {
    data: {
      default: null,
    },
  },
  data: function () {
    return {
      localData: null,
      show: false,
      html: null,
      assetList: null,
      textCopied: false,
      imgCopied: false,
      htmlCopied: false,
      colorCopied: false,
    }
  },
  mounted() {
    this.init()
    this.localData = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    form() {
      return this.$refs.form
    },
  },
  methods: {
    async init() {
      this.assetList = await this.getPhishingResources("assets")
    },
    async submit() {
      this.$emit("save", this.localData)
    },
    close() {
      this.$emit("close")
    },
    openPreview(data) {
      this.html = this.localData.html
      this.show = true
    },
    closedPreview() {
      this.html = null
      this.show = false
    },

    // Function to copy the tag syntax to the clipboard
    copyToClipboard(text, stateProp) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this[stateProp] = true

          // Show the confirmation snackbar
          showSnackbar(this.$t("view.learning_phishing.copy_success"),"#1AC8AA")

          // Reset the state after 2 seconds
          setTimeout(() => {
            this[stateProp] = false
          }, 2000)
        })
        .catch((err) => {
          console.error("Erreur lors de la copie : ", err)
        })
    },
  },
}
</script>
