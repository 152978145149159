<template>
  <div v-if="localResults">
    <div class="d-flex justify-end mb-4">
      <v-btn
        color="orange"
        class="text-none rounded-lg mr-2"
        dark
        elevation="0"
        small
        @click="exportResults"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("label.results") }}
      </v-btn>

      <v-btn
        color="orange"
        class="text-none rounded-lg"
        dark
        elevation="0"
        small
        @click="exportCompletedSessions"
      >
        <v-icon left>mdi-download</v-icon>
        {{ $t("label.completed_sessions") }}
      </v-btn>
    </div>


    <v-data-table
      :headers="resultsHeaders"
      :items="localResults"
      :single-select="false"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:[`item.score`]="{ item }">
        <v-chip :color="getColor(item)" v-if="null != item.score">
                      <span style="color: white; font-weight: bold">{{
                          item.score
                        }}</span>
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { i18n } from "@/plugins/i18n"
import moment from "moment/moment"

export default {
  name: "oppens-learning-course-results",
  components: {},
  props: {
    results: {
      default: null,
    },
  },
  data: () => ({
    localResults: null,
    resultsHeaders: [
      {
        text: i18n.t("First name"),
        align: "start",
        sortable: true,
        value: "first_name",
      },
      {
        text: i18n.t("Last name"),
        align: "start",
        sortable: true,
        value: "last_name",
      },
      {
        text: i18n.t("Email"),
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: i18n.t("End of session"),
        align: "start",
        sortable: true,
        value: "session_stopped_at",
      },
      {
        text: i18n.t("Score"),
        align: "start",
        sortable: true,
        value: "score",
      },
      {
        text: i18n.t("employees.missing"),
        align: "start",
        sortable: true,
        value: "missing",
      },
    ],
  }),
  mounted() {
    this.localResults = JSON.parse(JSON.stringify(this.results))
  },
  methods: {
    getColor(item) {
      if (item.score >= 75) return "green"
      else if (item.score >= 50) return "orange"
      else if (item.score >= 0) return "red"
      return null
    },
    formatDate(str) {
      return moment(str).format("MMMM D, YYYY")
    },
    exportCompletedSessions() {
      this.$emit("export-sessions")
    },
    exportResults() {
      this.$emit("export")
    },
    close() {
      this.$emit("close")
    },
  },
}
</script>
